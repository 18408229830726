import React from "react";

import { graphql } from "gatsby";

import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import FraudWasteAbuseIcon from "assets/icons/fraud-waste-abuse-icon.svg";
import FraudWasteAbuseImage from "assets/images/fraud-waste-abuse/fwa-image.svg";
import FraudWasteAbuseStep1 from "assets/images/fraud-waste-abuse/fwa-step-1.svg";
import FraudWasteAbuseStep2 from "assets/images/fraud-waste-abuse/fwa-step-2.svg";
import FraudWasteAbuseStep3 from "assets/images/fraud-waste-abuse/fwa-step-3.svg";
import FraudWasteAbuseStep4 from "assets/images/fraud-waste-abuse/fwa-step-4.svg";
import TypeEpisodeIncoherence from "assets/images/fraud-waste-abuse/fwa-type-episode-incoherence.svg";
import TypeIncoherentPricing from "assets/images/fraud-waste-abuse/fwa-type-incoherent-pricing.svg";
import TypeLackOfTest from "assets/images/fraud-waste-abuse/fwa-type-lack-of-test.svg";
import TypeSuspiciousReferral from "assets/images/fraud-waste-abuse/fwa-type-suspicious-referral-patterns.svg";
import TypeTimeGeo from "assets/images/fraud-waste-abuse/fwa-type-time-or-geo-discrepancy.svg";
import TypeUnlikelyFrequency from "assets/images/fraud-waste-abuse/fwa-type-unlikely-treatment-frequency.svg";
import ArrowSeparatedImages from "components/ArrowSeparatedImages";
import Divider from "components/Divider";
import Hero from "components/Hero";
import HowWeDoIt from "components/HowWeDoIt";
import Next from "components/Next";
import PageContainer from "components/PageContainer";
import SEO from "components/SEO";
import WhatWeOffer from "components/WhatWeOffer";

const TitledImageWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 1em;
  h4 {
    font-size: 14px;
    font-weight: 600;
    text-transformation: uppercase;
    text-align: center;
    justify-self: center;
    align-self: start;
  }
  img {
    width: auto;
    height: 100px;
    max-width: 90%;
    justify-self: center;
    align-self: center;
  }
  @media (min-width: 750px) {
    grid-template-rows: 2fr 1fr;
    img {
      width: 15vw;
      height: 15vw;
      max-width: 100%;
    }
  }
  @media (min-width: 1024px) {
    img {
      width: 10vw;
      height: 10vw;
    }
  }
`;

const Item = ({ title, imgSrc, imgAlt }) => (
  <TitledImageWrapper>
    <img src={imgSrc} loading="lazy" alt={imgAlt} />
    <h4 style={{ color: "#fff" }}>{title}</h4>
  </TitledImageWrapper>
);

Item.propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
};

const Step = ({ title, imgSrc, imgAlt }) => (
  <TitledImageWrapper>
    <img src={imgSrc} loading="lazy" alt={imgAlt} />
    <h4>{title}</h4>
  </TitledImageWrapper>
);

Step.propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
};

const FraudWasteAbuseTypes = styled.div`
  display: grid;
  padding: 10vh calc(15vw / 2);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 2em;
  background-color: #00033f;
  background-image: linear-gradient(135deg, #00033f, #1f2058);
  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

FraudWasteAbuseTypes.Item = Item;

const FraudWasteAbusePage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <SEO
        title="Fraud Waste & Abuse"
        description="Smart claims platform for augmented insurance"
      />
      <Hero
        bgBlueGreen
        title={
          <>
            Fraud,
            <br />
            Waste & Abuse
          </>
        }
        img={
          <Hero.HeroImage
            src={FraudWasteAbuseImage}
            alt="mesh-failure"
            loading="lazy"
          />
        }
        divider={
          <Divider
            icon={<img src={FraudWasteAbuseIcon} alt="server-stack" />}
          />
        }
      >
        <p>
          {t(
            "Qantev helps clients detect complex over-billing and fraudulent patterns, at scale."
          )}
        </p>
      </Hero>
      <WhatWeOffer
        offerText={t(
          "Qantev's machine learning algorithms combine internal and external health claims data to pick up on patterns of unnecessary, mislabeled and fraudulent services."
        )}
      />
      <FraudWasteAbuseTypes>
        <FraudWasteAbuseTypes.Item
          title={t("Incoherent pricing")}
          imgSrc={TypeIncoherentPricing}
          imgAlt="Incoherent pricing"
        />
        <FraudWasteAbuseTypes.Item
          title={
            <Trans t={t} i18nKey="Time or geospatial discrepancies">
              Time or geospatial
              <br />
              discrepancies
            </Trans>
          }
          imgSrc={TypeTimeGeo}
          imgAlt="time or geographical discrepancy"
        />
        <FraudWasteAbuseTypes.Item
          title={
            <Trans t={t} i18nKey="Unlikely treatment frequency">
              Unlikely treatment
              <br />
              frequency
            </Trans>
          }
          imgSrc={TypeUnlikelyFrequency}
          imgAlt="documents stack"
        />
        <FraudWasteAbuseTypes.Item
          title={
            <Trans t={t} i18nKey="Suspicious referral patterns">
              Suspicious
              <br />
              referral patterns
            </Trans>
          }
          imgSrc={TypeSuspiciousReferral}
          imgAlt="profile-provider-cycle"
        />
        <FraudWasteAbuseTypes.Item
          title={
            <Trans t={t} i18nKey="Episode incoherence">
              Episode
              <br />
              incoherence
            </Trans>
          }
          imgSrc={TypeEpisodeIncoherence}
          imgAlt="list document warning"
        />
        <FraudWasteAbuseTypes.Item
          title={
            <Trans t={t} i18nKey="Lack of test before surgery">
              Lack of test
              <br />
              before surgery
            </Trans>
          }
          imgSrc={TypeLackOfTest}
          imgAlt="lack of test"
        />
      </FraudWasteAbuseTypes>

      <HowWeDoIt
        description={t(
          "By identifying patterns of organised abuses, Qantev's advanced machine learning algorithms can isolate outliers to help trigger targeted audits."
        )}
      >
        <ArrowSeparatedImages>
          <Step
            title={
              <Trans t={t} i18nKey="New sanitized claim">
                New sanitized <br /> claim
              </Trans>
            }
            imgSrc={FraudWasteAbuseStep1}
            imgAlt="fwa1"
          />
          <Step
            title={
              <Trans t={t} i18nKey="Unusual pattern anomalies detected">
                Unusual pattern <br /> anomalies detected
              </Trans>
            }
            imgSrc={FraudWasteAbuseStep2}
            imgAlt="fwa2"
          />
          <Step
            title={
              <Trans t={t} i18nKey="Highlights abnormal behaviors and outliers">
                Highlights abnormal <br /> behaviors and outliers
              </Trans>
            }
            imgSrc={FraudWasteAbuseStep3}
            imgAlt="fwa3"
          />
          <Step
            title={
              <Trans t={t} i18nKey="Trigger targeted audit by FWA team">
                Trigger targeted <br /> audit by FWA team
              </Trans>
            }
            imgSrc={FraudWasteAbuseStep4}
            imgAlt="fwa4"
          />
        </ArrowSeparatedImages>
      </HowWeDoIt>

      <Next
        text={
          <Trans t={t} i18nKey="Back to Overview">
            Back to
            <br />
            Overview
          </Trans>
        }
        to="/product"
      />
    </PageContainer>
  );
};

export default FraudWasteAbusePage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="Fraud, Waste & Abuse" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["fraudWasteAbuse", "common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
