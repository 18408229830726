import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import ArrowFullRight from "assets/icons/arrow-full-right.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 2em;
  width: 100%;

  img {
    width: 80%;
    height: 100%;
  }

  @media (min-width: 550px) {
    flex-direction: row;
    gap: 1em;
  }

  @media (min-width: 600px) {
    img {
      width: 120px;
    }
  }

  @media (min-width: 750px) {
    img {
      width: 150px;
      height: 100%;
    }
  }
`;

const SeparatorImg = styled.img`
  transform: rotate(90deg);
  width: 20px !important;

  @media (min-width: 550px) {
    transform: none;
  }
`;

const Separator = () => (
  <SeparatorImg src={ArrowFullRight} alt="arrow full right" />
);

// eslint-disable-next-line
const ArrowSeparatedImages = ({ children, separator }) => (
  <Wrapper>
    {children.map((child, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index.toString()}>
        {child}
        {index + 1 < children.length && <Separator />}
      </React.Fragment>
    ))}
  </Wrapper>
);

ArrowSeparatedImages.defaultProps = {
  separator: Separator,
};

ArrowSeparatedImages.propTypes = {
  children: PropTypes.node.isRequired,
  separator: PropTypes.element,
};

export default ArrowSeparatedImages;
